<template>
  <FixedColumnTable :isDropdownsWatcher="true">
    <table class="table main-table bordered text-center">
      <thead>
        <tr>
          <th scope="col">№</th>
          <th class="text-start" scope="col">Заявка</th>
          <th class="text-start" scope="col">Задача (группа)</th>
          <th scope="col">Статус</th>
          <th scope="col">Прогресс</th>
          <th scope="col"><span class="text-nowrap">Время выполнения</span></th>
          <th scope="col">
            Фактическое <span class="text-nowrap">время выполнения</span>
          </th>
          <th scope="col">Цех</th>
          <th scope="col">Участок</th>
          <th scope="col">Материал</th>
          <th scope="col"><span class="text-nowrap">Кол-во</span></th>
          <th scope="col">Единица измерения</th>
          <th scope="col">Подзадачи</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in planList" :key="item.id">
          <td :style="{ width: item.id.length }">{{ item.id }}</td>
          <td class="text-start">
            <router-link
              v-if="item.deal_title"
              class="main-link"
              :to="{
                name: 'formation-page',
                params: { id: parseInt(item.deal_id) },
              }"
              >{{ item.deal_title ?? "-" }}</router-link
            >
            <span v-else>-</span>
          </td>
          <td class="text-start">
            <router-link
              v-if="item.product"
              class="main-link"
              :to="{
                name: 'plan-task-page',
                params: { id: parseInt(item.id) },
              }"
              >{{ item.product?.title ?? "-" }}</router-link
            >
            <span v-else>-</span>
          </td>
          <td>
            <span
              class="main-circle"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              :title="item.status?.id ? item.status?.title : 'Не указан'"
              :class="
                item.status?.id ? getClassForStatus(item.status?.id) : 'danger'
              "
            ></span>
          </td>
          <td>
            <span
              class="text-nowrap"
              :class="{
                'text-danger': item.progress == 0,
                'text-warning': item.progress > 0 && item.progress < 100,
                'text-success': item.progress == 100,
              }"
              >{{ item.progress ? item.progress + "%" : "0%" }}</span
            >
          </td>
          <td>
            <span class="text-nowrap">{{ item.need_time ?? "-" }}</span>
          </td>
          <td>
            <span class="text-nowrap">{{ item.time ?? "-" }}</span>
          </td>
          <td>
            <span class="text-nowrap">{{
              item.manufacture ? item.manufacture?.title : "-"
            }}</span>
          </td>
          <td>
            <span class="text-nowrap">{{
              item.area ? item.area?.title : "-"
            }}</span>
          </td>
          <td>
            <button
              type="button"
              class="btn btn-outline-gold btn-icon"
              data-bs-toggle="modal"
              data-bs-target="#plan-material-modal"
              @click="$emit('update:productToShow', item.product?.id)"
            >
              <inline-svg
                width="24"
                height="24"
                :src="require('@/assets/images/info.svg')"
              />
            </button>
          </td>
          <td>{{ item.quantity }}</td>
          <td>{{ item.product?.measurement?.title ?? "-" }}</td>
          <td>
            <router-link
              :to="{
                name: 'subtasks-page',
                params: {
                  dealid: parseInt(item.deal_id),
                  taskid: parseInt(state.task_id),
                  id: parseInt(item.id),
                },
              }"
              class="btn btn-gold w-100"
              :disabled="item.subtasks?.length < 1"
            >
              Перейти
            </router-link>
          </td>
        </tr>

        <tr v-show="!planList.length && !loading">
          <td colspan="9">
            <span class="d-block text-bold my-3">Ничего не найдено!</span>
          </td>
        </tr>
      </tbody>
    </table>
  </FixedColumnTable>
</template>

<script>
import { ref, reactive, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import { errorAlert } from "@/helpers/alerts";
import { getClassForStatus } from "@/helpers/applicationStatus";
import { getDealStatuses } from "@/api/deal";
import { updateTaskStatus } from "@/api/plan";

import FixedColumnTable from "@/components/ui/FixedColumnTable.vue";

export default {
  components: { FixedColumnTable },
  props: ["planList", "loading", "refetch"],
  emits: ["update:productToShow", "update:loading"],
  setup(props, { emit }) {
    const route = useRoute();
    const state = reactive({
      task_id: route.params.id,
    });
    const store = useStore();
    const isAdmin = computed(() => store.getters.isAdmin);
    const applicationStatuses = ref([]);

    onMounted(async () => {
      try {
        applicationStatuses.value = await getDealStatuses();
      } catch {
        errorAlert("Ошибка загрузки, попробуйте еще раз!");
      }
    });

    const editTaskStatus = async (id, status_id) => {
      try {
        emit("update:loading", true);
        await updateTaskStatus(id, status_id);
        await props.refetch();
      } catch {
        emit("update:loading", false);
        errorAlert("Ошибка загрузки, попробуйте еще раз!");
      }
    };

    return {
      state,
      isAdmin,
      applicationStatuses,
      getClassForStatus,
      editTaskStatus,
    };
  },
};
</script>
