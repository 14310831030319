<template>
  <section class="depot">
    <div class="d-flex align-items-center justify-content-start mb-4">
      <router-link class="main-tab__title" :to="{ name: 'plan-page' }">
        Производство
      </router-link>
      <h2 class="main-tab__title active">Группы</h2>
    </div>

    <SubtaskGroupTableWrapper />
  </section>
</template>

<script>
import SubtaskGroupTableWrapper from "@/components/plan/SubtaskGroupTableWrapper.vue";

export default {
  name: "plan-page",
  components: { SubtaskGroupTableWrapper },
  setup() {
    return {};
  },
};
</script>
